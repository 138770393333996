export enum Pages {
    WalletConnect = 'walletConnect',
}

export enum Collections {
    MEMBERS = 'members',
    SETTINGS = 'settings',
    WALLET_CONNECT = 'walletConnect',
}


export enum ConnectWalletStatus {
    loading,
    initLoaded,
    connectingAccount,
    accountConnected,
    verifying,
    verified,
}

export const functionsUrl = process.env.REACT_APP_USE_EMULATORS
    ? 'http://127.0.0.1:5001/motoverse-bot/europe-west1'
    : 'https://europe-west1-motoverse-bot.cloudfunctions.net';

export enum FUNCTIONS {
    walletAuthVerify = 'walletAuthVerify',
}
