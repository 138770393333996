import React from 'react'

export default function HomePage() {
    return (
        <div className="center flex-column m-auto">
            <h1>Motobot</h1>
            <img src="/assets/helmet_bot_01.png" alt="bot" style={{
                maxWidth: '150px'
            }} />
            <p>Discord bot for <a href='https://motoverse.games'>Motoverse</a></p>
        </div>
    )
}
