import React from 'react';
import './App.scss';
import 'toastr/build/toastr.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"
import HomePage from './pages/HomePage';
import WalletConnectPage from './pages/WalletConnectPage';
import { Pages } from './utils/constants';
import { FirebaseAppProvider, FirestoreProvider, useFirebaseApp } from 'reactfire';
import firebaseConfig from './utils/firebase';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';


const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: '/' + Pages.WalletConnect,
    element: <WalletConnectPage />,
  },
]);



function FirebaseApp() {

  const firestoreInstance = getFirestore(useFirebaseApp());
  if (process.env.REACT_APP_USE_EMULATORS) {
    connectFirestoreEmulator(firestoreInstance, 'localhost', 8080);
  }

  return <FirestoreProvider sdk={firestoreInstance}>

    <RouterProvider router={router} />
  </FirestoreProvider>

}

function App() {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <FirebaseApp />
    </FirebaseAppProvider>
  );
}

export default App;
