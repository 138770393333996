import React, { useEffect, useState } from 'react'
import toastr from 'toastr';
import useQueryParams from '../hooks/useQueryParams';
import { ProcessButton } from '../components/ProcessButton';
import { Collections, ConnectWalletStatus, FUNCTIONS, functionsUrl } from '../utils/constants';
import { doc, onSnapshot, Timestamp } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import WalletConnectInit from '../models/WalletConnectInit';
import { SiweMessage } from 'siwe';
import { ethers } from 'ethers';


export default function WalletConnectPage() {
    const provider = (window as any).ethereum;
    const [account, setAccount] = React.useState<string | null>(null);
    const [connectInit, setConnectInit] = useState<WalletConnectInit | null>(null);
    const [status, setStatus] = useState<ConnectWalletStatus>(ConnectWalletStatus.loading);
    const { initHash } = useQueryParams();
    const db = useFirestore();

    useEffect(() => {
        if (initHash && db) {
            const docRef = doc(db, Collections.WALLET_CONNECT, initHash);
            onSnapshot(docRef, (doc) => {
                if (doc.exists()) {
                    const newConnectInit = doc.data() as WalletConnectInit;
                    setConnectInit(newConnectInit);
                    if (newConnectInit.used) {
                        setStatus(ConnectWalletStatus.verified)
                        return;
                    }
                }
                setStatus(ConnectWalletStatus.initLoaded)
            });
        }
    }, [initHash, db])


    if (!provider) {
        return (
            <div className='center container m-auto flex-column'>
                <h1 className="mb-5 mt-2">You need a wallet to connect</h1>
                <p className="lead">Go to the MetaMask app, Browser tab and enter motoverse.games. <br />Or download the MetaMask
                    plugin for browser.</p>
                <p className="lead">If you do not have a MetaMask wallet, you can set it up for free</p>
                <a href='https://metamask.io/'>
                    <button type="button" className="btn btn-outline-primary btn-lg px-5 mt-5">
                        <img src="https://raw.githubusercontent.com/MetaMask/brand-resources/master/SVG/metamask-fox.svg" alt="metamask"
                            className="metamask-logo" /> Create MetaMask wallet
                    </button>
                </a>
            </div>
        )
    }

    const isExpired = status >= ConnectWalletStatus.initLoaded && (!connectInit || connectInit.expiresAt < Timestamp.now())
    if (isExpired) {
        return (
            <div className='center container m-auto flex-column'>
                <h1 className="mb-5 mt-2">This link has expired</h1>
                <p className="lead">Please try again.</p>
            </div>
        )
    }


    const connectWallet = async () => {
        setStatus(ConnectWalletStatus.connectingAccount)
        const handleNewAccounts = (accounts: string[]) => {
            if (accounts.length > 0) {
                const checksumAdd = ethers.utils.getAddress(accounts[0]);
                setAccount(checksumAdd);
                setStatus(ConnectWalletStatus.accountConnected)
            } else {
                toastr.error('No accounts found. Please connect your wallet to continue.')
                setStatus(ConnectWalletStatus.initLoaded);
            }
        }
        try {
            const accounts = await provider.request({ method: 'eth_requestAccounts' });
            handleNewAccounts(accounts)

        } catch (error) {
            console.error(error);
            toastr.error('Failed to fetch accounts');
        }
        provider.on('accountsChanged', handleNewAccounts)

    }

    const signMessage = async () => {
        if (!account) {
            toastr.error('No account found');
            return;
        }

        setStatus(ConnectWalletStatus.verifying)
        try {
            const message = new SiweMessage({
                domain: window.location.host,
                address: account,
                statement: `Connect to Discord`,
                uri: window.location.origin,
                version: '1',
                chainId: 1,
                nonce: initHash || '',
            });
            const preperaredMessage = message.prepareMessage();
            const ethersProvider = new ethers.providers.Web3Provider(provider);
            const signer = ethersProvider.getSigner();
            const signature = await signer.signMessage(preperaredMessage);

            const verifyResponse = await fetch(`${functionsUrl}/${FUNCTIONS.walletAuthVerify}`, {
                method: 'POST',
                body: JSON.stringify({
                    message,
                    signature

                })
            });
            const verifyResponseJson = await verifyResponse.json();
            if (!verifyResponseJson.ok) {
                throw new Error('Failed to verify signature');
            }

            setStatus(ConnectWalletStatus.verified);
        } catch (e) {
            console.error(e);
            toastr.error('Failed to sign message');
            setStatus(ConnectWalletStatus.accountConnected);
        }

    }


    return (
        <div className='center m-auto flex-column'>
            <h1>{"Connect Wallet <> Discord"}</h1>

            <div className='center flex-column mt-3 mb-5'>
                <img src={connectInit?.avatarUrl} alt={connectInit?.username} />
                <div>{connectInit?.username}</div>

            </div>

            <div style={{ width: '400px', maxWidth: '100vw' }}>

                <div className='row align-items-center'>
                    <div className='col-2'>
                        1
                    </div>
                    <div className='col-6'>
                        Connect Wallet
                    </div>
                    <div className='col-4 center'>
                        <ProcessButton
                            action={connectWallet}
                            currentStatus={status}
                            activeStatus={ConnectWalletStatus.initLoaded}
                            processingStatus={ConnectWalletStatus.connectingAccount}
                            icon='bi-wallet'
                            label='Connect'
                        />
                    </div>
                </div>
                {account && <div className='center mt-2 bold'>{account}</div>}
                <div className='row align-items-center mt-4'>
                    <div className='col-2'>
                        2
                    </div>
                    <div className='col-6'>
                        Sign Message
                    </div>
                    <div className='col-4 center'>
                        <ProcessButton
                            action={signMessage}
                            currentStatus={status}
                            activeStatus={ConnectWalletStatus.accountConnected}
                            processingStatus={ConnectWalletStatus.verifying}
                            icon='bi-unlock'
                            label='Sign'
                        />
                    </div>
                </div>

                {connectInit?.used && <div className='alert alert-success center flex-column mt-4'>
                    <p className='m-2'>

                        Connected successfully!
                    </p>
                    <p className='m-2'>
                        You can close this window.
                    </p>
                </div>}

            </div>
        </div>
    )
}
