import React from "react";
import { ConnectWalletStatus } from "../utils/constants";

interface Props {
    action: () => void,
    currentStatus: ConnectWalletStatus
    activeStatus: ConnectWalletStatus,
    processingStatus: ConnectWalletStatus,
    label: string,
    icon: string,
}

export function ProcessButton(props: Props) {


    let connectBtnClasses = 'btn d-flex'
    let label = props.label
    const isDone = props.currentStatus > props.processingStatus;
    if (isDone) {
        connectBtnClasses += ' btn-success'
        label += 'ed'
    } else {
        connectBtnClasses += ' btn-primary'
    }
    if (props.currentStatus === props.processingStatus) {
        label += 'ing'
    }

    const renderIcon = () => {
        if (isDone) {
            return <i className="bi bi-check me-2" />
        }
        if (props.currentStatus === props.processingStatus) {
            return <i className="spinner-grow spinner-grow-sm me-2" />
        }
        return <i className={`bi ${props.icon} me-2`} />
    }


    return <button className={connectBtnClasses} onClick={props.action}
        disabled={props.currentStatus !== props.activeStatus}>
        {renderIcon()} {label}
    </button>;
}
